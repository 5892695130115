import React, { useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Image,
  InputGroup,
  InputLeftAddon,
  Select,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { CardSinglePanel, InputBox } from '@components/common';
import { InstanceKey, InstanceKeyObject } from '@types';
import { JS_FRAMEWORKS } from '@constants';
import { useLocation, useFrontendAPI } from '@hooks';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import copy from 'clipboard-copy';
import { svgUrl } from '@utils';
import { EnvVar } from '@components/common/EnvVar';
import {
  ClipboardCheckIcon,
  ClipboardIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/solid';
import { useAnalytics } from '@hooks/useAnalytics';
import { getValueToCopy } from '@utils';

export function QuickCopyWidget(): JSX.Element {
  const { track } = useAnalytics();
  const [framework, setFramework] = useState(JS_FRAMEWORKS[0]);
  const {
    isOpen: isSecretKeyShown,
    onOpen: showSecretKey,
    onClose: hideSecretKey,
  } = useDisclosure();
  const { instanceId } = useLocation();
  const frontEndApiUrl = useFrontendAPI();

  const urls = { chrome: frontEndApiUrl };

  const [hasCopied, setHasCopied] = useState(false);

  const { data: instanceKeys } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );

  const publishableKey =
    instanceKeys?.find(key => key.object === InstanceKeyObject.FapiKey) ||
    ({
      secret: '',
    } as InstanceKey);

  const secretKey =
    instanceKeys?.find(key => key.object === InstanceKeyObject.SecretKey) ||
    ({
      secret: '',
    } as InstanceKey);

  const handleFrameworkSelection = e => {
    const { value } = e.target;
    const framework = JS_FRAMEWORKS.find(f => f.slug === value);
    setFramework(framework);
    setHasCopied(false);

    track('Dashboard_API Keys Screen_Quick Copy Framework Dropdown Selected', {
      surface: 'Dashboard',
      location: 'API Keys Screen',
      instanceId,
      selected: framework.name,
    });
  };

  // TEMP
  const onCopy = async () => {
    await copy(
      getValueToCopy(
        framework,
        publishableKey,
        secretKey,
        urls[framework.slug],
      ),
    );
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 1500);

    track(
      'Dashboard_API Keys Screen_Quick Copy Copy to Clipboard Button Clicked',
      {
        surface: 'Dashboard',
        location: 'API Keys Screen',
        instanceId,
      },
    );
  };
  return (
    <CardSinglePanel
      title={
        <Flex justifyContent='space-between' width='100%'>
          <Flex flexDirection='column'>
            <Text textStyle='lg-medium'>Quick Copy</Text>

            <Flex justifyContent='space-between' width='100%'>
              <Flex>
                <Text
                  color='rgba(0, 0, 0, 0.65)'
                  as='span'
                  textStyle='sm-normal'
                  mt='2'
                >
                  Select your framework, then copy and paste the code snippet
                  into your environment file.
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex>
            <InputBox alignItems='end' label='' flex='1' padding={0}>
              <InputGroup>
                <InputLeftAddon padding={0}>
                  <Image
                    src={svgUrl(framework.slug)}
                    mr='4'
                    boxSize='20px'
                    marginRight='10px'
                    marginLeft='10px'
                  />
                </InputLeftAddon>

                <Select
                  aria-label='Framework'
                  onChange={handleFrameworkSelection}
                  color='gray.500'
                  borderTopLeftRadius={0}
                  borderBottomLeftRadius={0}
                  background='auto'
                  width='220px'
                >
                  {JS_FRAMEWORKS.map(item => (
                    <option key={item.slug} value={item.slug}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </InputBox>
          </Flex>
        </Flex>
      }
      backgroundColor='gray.50'
      border='none'
    >
      <Tabs variant='enclosed' mt={3}>
        <TabPanels
          backgroundColor='gray.900'
          borderTopLeftRadius='md'
          borderTopRightRadius='md'
          borderBottomLeftRadius='md'
          borderBottomRightRadius='md'
          fontSize='sm'
        >
          <TabPanel paddingTop={0}>
            <Flex direction='column' p={4}>
              <HStack
                mb={2}
                justifyContent='space-between'
                width='100%'
                spacing={1}
              >
                <Text color='white'>{framework.envFile}</Text>
                <Flex gap='2'>
                  {framework.secret_key && !isSecretKeyShown && (
                    <Tooltip hasArrow placement='top' label='Show'>
                      <IconButton
                        aria-label='Show'
                        icon={<Icon as={EyeIcon} boxSize={5} />}
                        onClick={() => {
                          showSecretKey();
                          track(
                            'Dashboard_API Keys Screen_Quick Copy Reveal Keys Toggle Selected',
                            {
                              surface: 'Dashboard',
                              location: 'API Keys Screen',
                              instanceId,
                              state: 'Revealed',
                            },
                          );
                        }}
                        variant='ghost'
                        color='white'
                        bg='none'
                        h='auto'
                        minW='none'
                        _focus={{ bg: 'none' }}
                        _hover={{ bg: 'none' }}
                      />
                    </Tooltip>
                  )}

                  {framework.secret_key && isSecretKeyShown && (
                    <Tooltip hasArrow placement='top' label='Hide'>
                      <IconButton
                        aria-label='Hide'
                        icon={<Icon as={EyeOffIcon} boxSize={5} />}
                        onClick={() => {
                          hideSecretKey();
                          track(
                            'Dashboard_API Keys Screen_Quick Copy Reveal Keys Toggle Selected',
                            {
                              surface: 'Dashboard',
                              location: 'API Keys Screen',
                              instanceId,
                              state: 'Hidden',
                            },
                          );
                        }}
                        variant='ghost'
                        color='white'
                        h='auto'
                        minW='none'
                        bg='none'
                        _focus={{ bg: 'none' }}
                        _hover={{ bg: 'none' }}
                      />
                    </Tooltip>
                  )}

                  <Tooltip hasArrow placement='top' label='Copy'>
                    <IconButton
                      aria-label={hasCopied ? 'Copied' : 'Copy'}
                      icon={
                        <Icon
                          as={hasCopied ? ClipboardCheckIcon : ClipboardIcon}
                          boxSize={5}
                        />
                      }
                      onClick={onCopy}
                      variant='ghost'
                      color='white'
                      h='auto'
                      minW='none'
                      bg='none'
                      _focus={{ bg: 'none' }}
                      _hover={{ bg: 'none' }}
                    />
                  </Tooltip>
                </Flex>
              </HStack>
              <Box whiteSpace='nowrap' overflowX='scroll' lineHeight={1.8}>
                {framework.publishable_key && (
                  <>
                    <EnvVar
                      name={framework.publishable_key}
                      value={publishableKey?.secret}
                      index={1}
                      isShown={true}
                    />
                  </>
                )}

                {framework.secret_key && (
                  <>
                    <EnvVar
                      name={framework.secret_key}
                      value={secretKey?.secret}
                      isShown={isSecretKeyShown}
                      index={2}
                    />
                  </>
                )}

                {framework.url && (
                  <>
                    <EnvVar
                      name={framework.url}
                      value={urls[framework.slug]}
                      index={framework.secret_key ? 3 : 2}
                      isShown={true}
                    />
                  </>
                )}
              </Box>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CardSinglePanel>
  );
}
