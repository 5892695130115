import React, { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  useLocation,
  useAnalytics,
  useFrontendAPI,
  useDashboardSWR,
} from '@hooks';
import {
  ClipboardCheckIcon,
  ClipboardIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/solid';
import { getValueToCopy } from '@utils';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { EnvVar } from '@components/common/EnvVar';
import {
  Framework as FrameworkType,
  InstanceKey,
  InstanceKeyObject,
} from '@types';
import copy from 'clipboard-copy';

const editorOptions: editor.IStandaloneEditorConstructionOptions = {
  autoIndent: 'full',
  codeLens: false,
  minimap: { enabled: false },
  padding: { top: 0, bottom: 0 },
  quickSuggestions: false,
  acceptSuggestionOnEnter: 'off',
  acceptSuggestionOnCommitCharacter: false,
  tabCompletion: 'off',
  readOnly: true,
  scrollbar: {
    vertical: 'hidden',
    horizontal: 'hidden',
    handleMouseWheel: false,
  },
  fontSize: 13,
  contextmenu: false,
  lineNumbers: 'off',
};

const javascriptSnippet = (publishable_key: string, url: string) => {
  return `<script
  async
  crossorigin="anonymous"
  data-clerk-publishable-key="${publishable_key}"
  onload="window.Clerk.load()"
  src="https://${url}/npm/@clerk/clerk-js@4/dist/clerk.browser.js"
  type="text/javascript">
</script>`;
};

type QuickstartGuidTabs = {
  framework: FrameworkType;
  snippetOpen: boolean;
};

export function QuickstartGuidTabs({
  framework,
  snippetOpen,
}: QuickstartGuidTabs): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();
  const [hasCopied, setHasCopied] = useState(false);
  const {
    isOpen: isSecretKeyShown,
    onOpen: showSecretKey,
    onClose: hideSecretKey,
  } = useDisclosure();
  const frontEndApiUrl = useFrontendAPI();

  const urls = { javascript: frontEndApiUrl };

  const { data: instanceKeys } = useDashboardSWR<InstanceKey[]>(
    `/v1/instances/${instanceId}/instance_keys`,
  );

  const publishableKey =
    instanceKeys?.find(key => key.object === InstanceKeyObject.FapiKey) ||
    ({
      secret: '',
    } as InstanceKey);

  const secretKey =
    instanceKeys?.find(key => key.object === InstanceKeyObject.SecretKey) ||
    ({
      secret: '',
    } as InstanceKey);

  const onCopy = async () => {
    if (framework.codeSnippet) {
      await copy(
        javascriptSnippet(publishableKey.secret, urls[framework.slug]),
      );
    } else {
      await copy(
        getValueToCopy(
          framework,
          publishableKey,
          secretKey,
          urls[framework.slug],
        ),
      );
    }

    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 1500);

    track(
      'Dashboard_Instance Overview_Start Building Copy to Clipboard Button Clicked',
      {
        surface: 'Dashboard',
        location: 'Instance Overview',
        instanceId,
      },
    );
  };

  return (
    <Tabs
      variant='enclosed'
      mt={3}
      as={motion.div}
      initial={{ opacity: 0, height: 0 }}
      animate={snippetOpen ? 'open' : 'closed'}
      variants={{
        open: { opacity: 1, height: 'auto' },
        closed: { opacity: 0 },
      }}
    >
      <TabPanels
        backgroundColor='gray.900'
        borderTopLeftRadius='md'
        borderTopRightRadius='md'
        borderBottomLeftRadius='md'
        borderBottomRightRadius='md'
        fontSize='sm'
      >
        <TabPanel paddingTop={0}>
          <Flex direction='column' p={4}>
            <HStack
              mb={2}
              justifyContent='space-between'
              width='100%'
              spacing={1}
            >
              <Text color='white'>{framework.envFile}</Text>
              <Flex gap='2'>
                {framework.secret_key && !isSecretKeyShown && (
                  <Tooltip hasArrow placement='top' label='Show'>
                    <IconButton
                      aria-label='Show'
                      icon={<Icon as={EyeIcon} boxSize={5} />}
                      onClick={() => {
                        showSecretKey();
                        track(
                          'Dashboard_Instance Overview_Start Buildin Reveal Keys Toggle Selected',
                          {
                            surface: 'Dashboard',
                            location: 'Instance Overview',
                            instanceId,
                            state: 'Revealed',
                          },
                        );
                      }}
                      variant='ghost'
                      color='white'
                      bg='none'
                      h='auto'
                      minW='none'
                      _focus={{ bg: 'none' }}
                      _hover={{ bg: 'none' }}
                    />
                  </Tooltip>
                )}

                {framework.secret_key && isSecretKeyShown && (
                  <Tooltip hasArrow placement='top' label='Hide'>
                    <IconButton
                      aria-label='Hide'
                      icon={<Icon as={EyeOffIcon} boxSize={5} />}
                      onClick={() => {
                        hideSecretKey();
                        track(
                          'Dashboard_Instance Overview_Start Buildin Reveal Keys Toggle Selected',
                          {
                            surface: 'Dashboard',
                            location: 'Instance Overview',
                            instanceId,
                            state: 'Hidden',
                          },
                        );
                      }}
                      variant='ghost'
                      color='white'
                      h='auto'
                      minW='none'
                      bg='none'
                      _focus={{ bg: 'none' }}
                      _hover={{ bg: 'none' }}
                    />
                  </Tooltip>
                )}

                <Tooltip hasArrow placement='top' label='Copy'>
                  <IconButton
                    aria-label={hasCopied ? 'Copied' : 'Copy'}
                    icon={
                      <Icon
                        as={hasCopied ? ClipboardCheckIcon : ClipboardIcon}
                        boxSize={5}
                      />
                    }
                    onClick={onCopy}
                    variant='ghost'
                    color='white'
                    h='auto'
                    minW='none'
                    bg='none'
                    _focus={{ bg: 'none' }}
                    _hover={{ bg: 'none' }}
                  />
                </Tooltip>
              </Flex>
            </HStack>
            <Box whiteSpace='nowrap' overflowX='scroll' lineHeight={1.8}>
              {framework.codeSnippet ? (
                <>
                  <Editor
                    theme='vs-dark'
                    language='json'
                    value={javascriptSnippet(
                      publishableKey?.secret,
                      urls[framework.slug],
                    )}
                    options={editorOptions}
                    height={170}
                  />
                </>
              ) : (
                <>
                  {framework.publishable_key && (
                    <>
                      <EnvVar
                        name={framework.publishable_key}
                        value={publishableKey?.secret}
                        index={1}
                        isShown={true}
                      />
                    </>
                  )}

                  {framework.secret_key && (
                    <>
                      <EnvVar
                        name={framework.secret_key}
                        value={secretKey?.secret}
                        isShown={isSecretKeyShown}
                        index={2}
                      />
                    </>
                  )}

                  {framework.url && (
                    <>
                      <EnvVar
                        name={framework.url}
                        value={urls[framework.slug]}
                        index={framework.secret_key ? 3 : 2}
                        isShown={true}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
