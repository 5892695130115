import React, { useState } from 'react';

import { START_BUILDING_FRAMEWORKS } from '@constants';
import { Framework } from '@types';
import { useLocation, useAnalytics } from '@hooks';
import { CardSinglePanel } from '@components/common';

import { QuickstartGuidTiles } from './QuickstartGuidTiles';
import { QuickstartGuidTabs } from './QuickstartGuidTabs';

export function StartBuilding(): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();
  const [framework, setFramework] = useState<Framework>(
    START_BUILDING_FRAMEWORKS[0],
  );
  const [isSnippetOpen, setIsSnippetOpen] = useState(false);

  const onHoverStart = (startItem: Framework) => {
    setIsSnippetOpen(true);
    setFramework(
      START_BUILDING_FRAMEWORKS.find(item => item.slug === startItem.slug),
    );
    track('Dashboard_Instance Overview_Start Building Card Link Hovered', {
      surface: 'Dashboard',
      location: 'Instance Overview',
      instanceId,
      clickedOn: startItem.name,
    });
  };

  return (
    <CardSinglePanel
      title='Quickstarts'
      subtitle='Choose your framework below. The average installation takes only 16 minutes.'
      subtitleStyles={{ mb: 6 }}
    >
      <QuickstartGuidTiles hoverStart={onHoverStart} />
      <QuickstartGuidTabs framework={framework} snippetOpen={isSnippetOpen} />
    </CardSinglePanel>
  );
}
